<template>
  <v-hover v-slot="{ hover }">
    <v-card
      flat
      v-resize="onResize"
      :height="!mini ? height!==null ? height : view.height : 'auto'"
      :width="width"
      :loading="loading"
      ref="card"
      :class="{ overflow }"
      class="data-card overview d-flex flex-column rounded-lg"
      @click="open"
    >
      <v-card-actions 
        ref="header"
        class="header flex-shrink-0 justify-space-between pa-8 pb-4"
      >
        <h4 
          class="title text-subtitle-1 font-weight-medium"
        >
          {{ title }}
        </h4>
        <p 
          v-show="chart.type=='Gallery'"
          flat
          color="rgba(255,255,255,.9)"
          class="hint font-weight-bold text-body-2 mb-0 off"
        >
          {{ chart.hint }}
        </p>
        <v-btn
          v-if="toggleable&&!loading"
          icon
          right
        >
          <v-icon>{{ mini ? icons.mdiChevronDown : icons.mdiChevronUp }}</v-icon>
        </v-btn>
        <!-- <v-progress-circular
          v-else-if="loading"
          indeterminate
          size="24"
          width="3"
          color="grey lighten-2"
          class="loading mr-2"
        /> -->
      </v-card-actions>
      <slot name="content">
        <v-card-text 
          v-if="Array.isArray(number)"
          ref="text"
          class="numbers d-flex px-8 pt-0 pb-4"
        >
          <div
            class="numbers-simple-gauge d-flex flex-column flex-grow-1"
          >
            <span 
              class="number text-h4 text-lg-h3 font-weight-black"
              :class="[(color[0])+'--text text--lighten-1']"
            >
              {{ number[0].value == null ? '-' : number[0].value+'%' }}
            </span>
            <span 
              v-html="number[0].hint.text"
              class="hint text-body-2 font-weight-bold pt-4 pb-4 off" 
            />
          </div>
          <div
            class="numbers-simple-gauge d-flex flex-column align-end flex-grow-1"
          >
            <span 
              class="number text-h4 text-lg-h3 font-weight-black text-right"
              :class="[(color[1])+'--text text--lighten-1']"
            >
              {{ number[1].value == null ? '-' : number[1].value+'%' }}
            </span>
            <span 
              v-html="number[1].hint.text"
              class="hint text-body-2 font-weight-bold pt-4 pb-4 off text-right" 
            />
          </div>
        </v-card-text>
        <v-card-text 
          v-else-if="number!=null"
          ref="text"
          class="d-flex flex-column px-8 pt-0 pb-4"
          :class="{ 'pb-8': chart.type==null }"
        >
          <span 
            class="number text-h4 text-lg-h3 font-weight-black"
            :class="[ color+'--text' ]"
          >
            {{ number.value | format(number.format) }}
          </span>
          <span 
            v-if="number.hint!=null&&number.hint.text!=null"
            v-html="number.hint.text"
            class="hint text-body-2 font-weight-bold pt-4 pb-4" 
            :class="[!!number.hint.color ? number.hint.color+'--text' : 'off']"
          />
        </v-card-text>

        <div
          v-if="chart.type=='SimpleColumn'"
          class="chart mt-auto simple-column flex-grow-0 flex-shrink-0 d-flex align-stretch"
        >
          <div 
            v-for="(d, i) in (chart.data==null ? animation.data : chart.data)"
            :key="`simple-column-${title}-${i}`"
            class="simple-column-item flex-grow-1 d-flex align-end"
          >
            <v-tooltip 
              top
              :disabled="typeof d == 'string'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div 
                  class="simple-column-bar flex-grow-1"
                  :class="[(chart.data==null ? 'grey' : Array.isArray(color) ? color[i] : color)]"
                  :style="{ 'height': typeof d == 'string' ? d : d.value }"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              {{ d.text }}
            </v-tooltip>
          </div>
        </div>
        <div
          v-if="chart.type=='SimpleRow'"
          class="chart mt-auto simple-row flex-grow-0 flex-shrink-0"
          style="{ 'max-height': maxHeight }"
        >
          <div 
            v-for="(d, i) in (chart.data==null ? animation.data : chart.data)"
            :key="`simple-row-${title}-${i}`"
            class="simple-row-item d-flex align-center"
          >
            <div 
              class="simple-row-bar align-self-end"
              :class="[(chart.data==null ? 'grey' : Array.isArray(color) ? color[i] : color)]"
              :style="{ 'width': typeof d == 'number' ? d : d.value, 'height': typeof d == 'number'||!d.hasOwnProperty('height') ? null : d.height }"
            />
            <span class="hint text-body-2 font-weight-bold text-no-wrap pl-8">
              {{ typeof d == 'string' ? null : d.text }}
            </span>
          </div>
        </div>
        <div
          v-else-if="chart.type=='SimpleGauge'"
          class="chart mt-auto simple-gauge flex-grow-0 flex-shrink-0"
        >
          <ul 
            class="simple-gauge-container"
          >
            <li
              v-for="(d, i) in (chart.data==null ? animation.data : chart.data)"
              :key="`simple-gauge-${title}-${i}`"
              class="simple-gauge-slice"
              :style="{ 'z-index': chart.data.length-i, 'border-color': d.color, 'transform': `rotate(${$options.filters.calcGaugeRotation(d.value, i, ((chart.data==null ? animation.data : chart.data)))}deg)` }"
            />
          </ul>
        </div>
        <simple-table
          v-else-if="chart.type=='SimpleTable'"
          :data="chart.data"
          :headers="chart.headers"
          :height="maxHeight||views.chart"
          :loading="loading"
          class="chart simple-table pt-0 flex-grow-1 flex-shrink-1"
        />
        <div
          v-else-if="chart.type=='Sparkline'"
          class="chart mt-auto sparkline flex-grow-0 flex-shrink-0"
        >
          <GChart
            type="AreaChart"
            :data="chart.data==null ? animation.data : chart.data"
            :options="chartOptions"
            class="chart minimal"
          />
        </div>
        <div
          v-else-if="chart.type=='Gallery'"
          class="chart gallery mt-2 pb-1 scrollable fill-height"
        >
          <v-lazy
            v-for="(media, p) in chart.data"
            :key="'media-gallery-'+p"
            v-model="view.gallery.inView[p]"
            :options="{
              threshold: .125
            }"
            min-height="25vw"
            transition="fade-transition"
          >
            <gallery-player
              :url="media.url"
              :in-view="view.gallery.ready[p]&&view.gallery.inView[p]"
              :autoplay="view.gallery.inView[p]"
              square
              class="pa-0 pb-1"
              @ready="(b) => onGalleryReady(p, b)"
            />
          </v-lazy>
        </div>
      </slot>
      <slot name="actions" />
    </v-card>
  </v-hover>
</template>

<style>

  :root {
    --chart-height: 12vh;
  }
  @media (orientation: portrait) {
    :root {
      --chart-height: 10vh;
    }
  }

  .data-card.overview {
    filter: drop-shadow(0px 4px 12px rgba(61, 75, 143, 0.16));
  }
  .data-card.overview:not(.overflow) {
    overflow: hidden;
  }
  /* .data-card.overview .chart {
    overflow: hidden;
  } */

  .data-card .header.gallery {
    position: absolute;
    width: 100%;
    z-index: 1;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255,255,255, 0.6) 25%, rgba(255,255,255, 0.8) 40%, rgba(255, 255, 255, 0.96) 96%) */
  }

  .data-card.overview .title {
    font-size: 1.125rem !important;
    opacity: .6;
  }
  .data-card.overview .number {
    /* font-size: 2.5rem !important; */
  }

  .data-card.overview .off {
    opacity: .6;
  }

  .data-card.overview .secondary-number {
    font-size: .875em !important;
  }

  .data-card.overview .placeholder.loading {
    animation: loading 2s infinite ease-in-out;
  }

  .data-card.overview .chart {
    opacity: .6;
    will-change: opacity;
    transition: opacity 1000ms ease-in-out;
  }

  .data-card.overview .chart.animate {
    opacity: 1;
  }

  .data-card.overview .chart.sparkline {
    opacity: .6;
    margin-bottom: -1px;
  }

  .data-card.overview .chart.simple-column {
    min-height: var(--chart-height);
    will-change: opacity, transform;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    /* transform: translateY(100%); */
  }
  .data-card.overview .chart.simple-column.animate {
    transform: translateY(0);
  }
  .data-card.overview .chart .simple-column-bar {
    opacity: .32;
    will-change: opacity, transform;
    transition: opacity 250ms ease-in-out, height 250ms linear;
  }
  .data-card.overview .chart .simple-column-item:not(:last-child) {
    margin-right: 2px;
  }

  .data-card.overview .chart.simple-row {
    will-change: opacity, transform;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    /* transform: translateY(100%); */
    /* margin-bottom: 2px; */
    overflow-y: scroll;
  }
  .data-card.overview .chart.simple-row.animate {
    transform: translateY(0);
  }
  .data-card.overview .chart .simple-row-item {
    height: 48px;
  }
  .data-card.overview .chart .simple-row-bar {
    height: 100%;
    opacity: .32;
    will-change: opacity, transform;
    transition: opacity 250ms ease-in-out, width 250ms linear;
  }
  .data-card.overview .chart .simple-row-item .hint {
    position: absolute;
  }
  .data-card.overview .chart .simple-row-item {
    margin-bottom: 2px;
  }

  .simple-gauge {
    opacity: .6;
  }
  .simple-gauge-container {
    position: relative;
    height: var(--chart-height);
    width: calc(2 * var(--chart-height));
    overflow: hidden;
    margin: 0 auto;
    padding: 0 !important;
    list-style-type: none;
    will-change: transform;
    transition: transform 1000ms ease-in-out;
    /* transform: rotate(-180deg); */
    transform-origin: 50% 100%;
  }
  .simple-gauge-container.animate {
    transform: rotate(0deg);
  }

  .simple-gauge-container *,
  .simple-gauge-container::before {
    box-sizing: border-box;
  }

  .simple-gauge-container::before,
  .simple-gauge-container::after {
    position: absolute;
  }
  
  .simple-gauge-container::before {
    content: '';
    width: inherit;
    height: inherit;
    border: 3rem solid var(--light-border);
    border-bottom: none;
    border-top-left-radius: var(--chart-height);
    border-top-right-radius: var(--chart-height);
  }

  .simple-gauge-container li {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    border: 3.5rem solid;
    border-top: none;
    border-bottom-left-radius: var(--chart-height);
    border-bottom-right-radius: var(--chart-height);
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  .chart.gallery {
    opacity: 1 !important;
    /* max-height: 80vh; */
  }
  

  @keyframes loading {
    0% {
      opacity: .4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: .4;
    }
  }
  
</style>

<script>
  import { mdiHelpCircleOutline } from '@mdi/js'
  import services from '@/services';
  import numeral from 'numeral'

  export default {

    components: {
      SimpleTable: () => import('@/components/SimpleTable'),
      GalleryPlayer: () => import('@/components/GalleryPlayer'),
    },

    props: {
      title: {
        type: String,
        default: 'Título'
      },
      number: {
        type: [Array, Object],
        default: () => {
          return {
            value: null,
            format: null,
            hint: null,
          }
        },
      },
      chart: {
        type: Object,
        default: () => {
          return {
            type: null,
            data: null,
            length: 7,
          }
        }
      },
      color: {
        type: [String, Array],
        default: 'accent',
      },
      route: {
        type: String,
        default: 'overview',
      },
      mini: {
        type: Boolean,
        default: false
      },
      toggleable: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false,
      },
      height: {
        type: [String, Number],
        default: null
      },
      maxHeight: {
        type: [String, Number],
        default: null
      },
      width: {
        type: [String, Number],
        default: '100%'
      },
      overflow: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
      },
      view: {
        width: 240,
        height: 240,
        debounce: {
          delay: 1000,
          resizing: false,
          timer: null
        },
        gallery: {
          inView: {},
          ready: {}
        }
      },
      default: {
        options: {
          chartArea: {
            width: '100%', 
            height: '100%',
          },
          height: 400,
          lineWidth: 4,
          legend: { 
            position: 'none' 
          },
          hAxis: {
            textPosition: 'none',
            gridlines: {
              color: 'transparent',
            },
            baselineColor: 'transparent',
            minorGridlines: {
              color: 'transparent'
            },
          },
          vAxis: {
            textPosition: 'none',
            gridlines: {
              color: 'transparent',
            },
            baselineColor: 'transparent',
            minorGridlines: {
              color: 'transparent'
            },
          },
          enableInteractivity: false,
          tooltip: {
            trigger: 'none'
          },
          colors: [
            '#698DF2',
            '#F49891',
          ],
          animation: {
            startup: true,
            duration: 250,
            easing: 'in-out',
          },
        }
      },
      animation: {
        toggle: false,
        data: null,
        interval: 250,
        controller: null,
        options: {
          vAxis: {
            viewWindow: {
              min: 0,
              max: 100
            }
          },
          colors: [
            '#ddd',
            '#eee',
          ],
        }
      },
      animate: false,
    }),

    computed: {
      chartOptions () {
        // console.log(this.default.options, this.chart.options);
        return this.animation.toggle ? _.merge({}, this.default.options, this.animation.options) : _.merge({}, this.default.options, this.chart.options);
      },
      chartHeight () {
        const header = _.has(this.$refs, 'header') ? this.$refs['header'].clientHeight : 76;
        const text = _.has(this.$refs, 'text') ? this.$refs['text'].clientHeight : 52;
        const card = this.view.height;
        const height = card - header - text;
        return height;
      },
      chartWidth () {
        return window.innerWidth * (this.breakpoint('md') ? .24 : .48);
      },
    },

    watch: {
      chart: {
        deep: true,
        immediate: true,
        handler (chart) {
          const nil = this.chart.type == 'SimpleGauge' ? this.chart.data[0].value==null : _.isNil(this.chart.data);
          // this.toggleLoading(nil);
          if (chart.type === 'Gallery' && this.breakpoint(null, 'xs') && chart.data.length > 1) {
            chart.data = [chart.data[0]]
            this.view.gallery.inView = _.mapValues(_.clone(chart.data), d => false);
          }
        }
      }
    },

    methods: {
      ...services,

      onGalleryReady (i, b) {
        this.view.gallery.ready[i] = b;
      },

      onResize () {
        if (!this.view.debounce.resizing) {
          this.view.debounce.resizing = true;
          this.view.debounce.timer = setTimeout(($) => {
            const width = _.has($.$refs, 'card') ? $.$refs['card'].$el.clientWidth : $.view.width;
            $.view.height = width * .75;
            $.default.options.height = $.chartHeight;
            $.view.debounce.resizing = false;
            $.view.debounce.timer = clearTimeout($.view.debounce.timer);
            // console.log('resized', this.view.debounce.resizing, width, $.view.height)
          }, this.view.debounce.delay, this);
        }
      },

      open () {
        this.$emit('click', this.route);
      },
    },

    filters: {
      calcGaugeRotation (value, index, data) {
        const deg = (value / 100) * 180;
        const start = _.reduce(data, (sum, d, i) => {
          return index>i ? sum + (d.value / 100) * 180 : sum;
        }, 0);
        console.log(deg, start);
        return deg + start;
      },
      format (value, format) {
        const formatted = numeral(value).format(format);
        // console.log(value, format, formatted);
        return value==null ? '-' : formatted.indexOf(':')>=0 && formatted.length>=8 ? numeral(value/60/60).format('0,0[.]0') + ' h' : formatted;
      }
    },

    mounted () {
      this.onResize();
    },
    beforeDestroy () {
      if (this.view.debounce.timer!=null) this.view.debounce.timer = clearTimeout(this.view.debounce.timer);
    },
  }
</script>